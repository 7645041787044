<template>
  <div class="container-cadastro">
        <!-- -->
 <section class="container-fluid bg-cadastro" style="">
      <section class="container" id="topo">
          <div class="row">
             <div class="col"><br><br><br><br><br><br><br>
                <h1 style="color:#FFF;" class="bold text-center">Acesse todas as receitas e demais<br>funcionalidades da plataforma!</h1><br>
             </div>
</div>
              <div class="row">
                <div class="col-sm-4 offset-sm-4" style="background-color:#FFF;border-radius:10px;">
                  <br>
                    <h4 @click="abrirModalCadastro" class="text-center bold bt-acao" >CADASTRE-SE</h4>
                    <h4 class="text-center bold">AGORA E PAGUE DEPOIS</h4>
                </div>
              </div>
              <div class="row">
              <div class="col">
                  <h1 style="color:#400041;" class="bold text-center mt-2">Taxa única</h1>
                   <h3 style="color:#FFF;" class=" text-center mt-2">Compre o acesso pelo tempo que quiser</h3>
              </div>
              </div>
          
           <div class="row mt-5">
                <div class="col-sm-4 " >
                  <div class="box-comprar" style="background-color:#FFF;border-radius:20px;">
                    <br>
                    <h4 class="text-center bold txt-preco">6 MESES<br>R$9,90</h4>
                      <h5 @click="comprar('9.90','Semestral',2)" class="text-center bold bt-acao" >{{textoBotao}}</h5>
                    </div>
                </div>
               <div class="col-sm-4 " >
                  <div class="box-comprar" style="background-color:#FFF;border-radius:20px;">
                    <br>
                    <h4 class="text-center bold txt-preco">1 ANO<br>R$19,90</h4>
                      <h5 @click="comprar('19.90','Anual',3)" class="text-center bold bt-acao" >{{textoBotao}}</h5>
                    </div>
                </div>
               <div class="col-sm-4 " >
                  <div class="box-comprar" style="background-color:#FFF;border-radius:20px;position:relative;">
                     <img src="/img/promocao.png" class="img-fluid promocao" style="position:absolute;right:-60px;top:-70px;"/>
                    <br>
                    <h4 class="text-center bold txt-preco">SEM LIMITE<br>R$19,90</h4>
                      <h5 @click="comprar('19.90','Permanente',4)" class="text-center bold bt-acao" >{{textoBotao}}</h5>
                    </div>
                </div>
              </div>
              <br><br>
      </section>
 </section>


 <!-- -->

 <div class="modal" id="modal-cadastro" tabindex="-1" >
  <div class="modal-dialog modal-lg modal-dialog-centered" >
    <div class="modal-content" style="background-color: #400041;">
      <div class="modal-header">
        <h4 class="modal-title text-white">Conta para Assinantes</h4>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
     

        <div class="row">

          <div class="col-sm-12 fundo-roxo quadro">
           
            
            <div class="espacamento">
           

 
  <p class="txt-cadastro">Para efetuarmos sua assinatura precisamos que preencha seu cadastro abaixo:</p>
              <!-- -->
                <!-- <div class="form-group">
                    <input class="form-control input-cadastro" name="cpf" placeholder="CPF" type="text" v-mask="'###########'" v-model="cpf"/>
                </div> -->

                <div class="form-group">
                    <input class="form-control input-cadastro" name="nome" placeholder="Nome" type="text" v-model="nome"/>
                </div>   

               

              

                 <div class="form-group">
                    <input class="form-control input-cadastro" name="data" placeholder="Data Nascimento" type="text" v-mask="'##/##/####'" v-model="data"/>
                </div>  
                
               

                <!-- <div class="form-group">
                    <select type="text" class="form-control input-cadastro" name="sexo" id="sexo" v-model="genero">
                       <option value="">Gênero</option>
                      <option value="Masculino">Masculino</option>
                      <option value="Feminino">Feminino</option>
                      <option value="Outro">Outro</option>
                    
                    </select>
                </div>  -->

                 <div class="form-group">
                    <input v-on:keyup="buscarCep" class="form-control input-cadastro" name="cep" placeholder="CEP" type="text" v-mask="'#####-###'" v-model="cep"/>
                </div>  

                <div class="form-group d-none">
                    <input class="form-control input-cadastro" name="cidade" placeholder="Cidade" type="text" v-model="cidade"/>
                </div> 

                  <div class="form-group d-none">
                    <select type="text" class="form-control input-cadastro" name="estado" v-model="estado">
                       <option value="">Estado</option>
                       <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                    
                    </select>
                </div>  

<button type="button" class="btn btn-secondary d-block w-100 text-dark" style="background-color:white;font-weight:bold;" @click="abrirModalInfo" >ENTENDA PORQUE LHE PEDIMOS ESSES DADOS</button>
<p class="txt-cadastro">Nós seguimos a Lei de Proteção de Dados Brasileira.  </p>

                <!-- <div class="form-group">
                    <select type="text" class="form-control input-cadastro" name="nivel" id="nivel" v-model="nivel">
                       <option value="">Nível de Pratica</option>
                        <option value="Iniciante">Iniciante</option>
                        <option value="Intermediário">Intermediário</option>
                        <option value="Avançado">Avançado</option>
                        <option value="Expert">Expert</option>
                    
                    </select>
                </div>  -->

                <div class="form-group">
                    <input class="form-control input-cadastro" name="promocao" placeholder="Código promocional (Caso possua)" type="text" v-model="promocao"/>
                </div> 


                <h5 class="txt-white">Sua História</h5>

                  <div class="form-group">
                    <textarea class="form-control input-cadastro" name="historia" maxlength="1000" placeholder="Conte para gente um pouco sobre você e o que espera da Tarimba na Cozinha" type="text" v-model="historia"></textarea>
                </div>   

           
  <div class="form-group">
                    <input class="form-control input-cadastro" name="email" placeholder="E-mail" type="email" v-model="email"/>
                </div>  

                <div class="form-group">
                    <input class="form-control input-cadastro" name="emailConfirma" placeholder="Confirmar E-mail" type="email" v-model="emailConfirma"/>
                </div>  

               

                <div class="form-group">
                    <input class="form-control input-cadastro" name="senha" placeholder="Senha" type="password" v-model="senha"/>
                </div> 

                <div class="form-group">
                    <input class="form-control input-cadastro" name="senhaConfirma" placeholder="Confirmar Senha" type="password" v-model="senhaConfirma"/>
                </div> 

                  <ul class="txt-white">
                
                     <li><input type="checkbox" name="termos" v-model="termos" />&nbsp;&nbsp;Li e aceito os <span class="bold" ><router-link style="font-size:0.8em !important;color:#FFF;"  to="/termos-de-uso" target="_blank">TERMOS DE USO</router-link></span></li>
                     <li><input type="checkbox" name="politica" v-model="politica" />&nbsp;&nbsp;Li e aceito as <span class="bold"><router-link style="font-size:0.8em !important;color:#FFF;" to="/politica-de-privacidade" target="_blank">POLÍTICAS DE PRIVACIDADE</router-link></span></li>
                     
                </ul>

                <button  v-if="showLoading == false" type="button" role="button" @click="validarCampos" class="bt-enviar-cadastro btn-primary bold" style="display:block !important;">FINALIZAR CADASTRO</button>
                  <div v-if="showLoading" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>

           </div> 
               

              <!-- -->

             
            </div>
          </div><!-- col -->

        
            


     
 </div>
      <div class="modal-footer">
         </div>
    </div>
 </div>
 </div>

<section class="container-fluid fundo-roxo" >
 <section class="container">
          <div class="row">
              <div class="col"><br><br><br>
                <h1 class="text-white text-center">Quer apenas experimentar por alguns dias?
</h1>
                <button type="button" class="btn btn-secondary d-block  text-dark mx-auto" style="background-color:white;font-weight:bold;font-size:1.3em;padding:15px; 20px;" @click="abrirModalDegustacao" >DEGUSTAÇÃO GRATUITA</button>
                <br><br><br>
            </div>
           </div>
</section>
</section>


 <Degustacao />

      <div class="modal" id="modal-validacao" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alerta da plataforma</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>


 <div class="modal" id="modal-info" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">O uso dos seus dados </h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Veja abaixo para que utilizamos cada dado que lhe pedimos</p>
        <ul>
          <li>Seu <strong>Nome</strong> será a sua identificação em comentários e  receitas;</li><br>
          
          <li>A <strong>Data de Nascimento, CEP </strong> e sua <strong>breve apresentação</strong> serão usados anonimamente para melhor ajustar e customizar nosso conteúdo do blog e ofertas de nossos parceiros, disponibilizadas nos espaços de anúncios;</li><br>
          <li>Seu <strong>e-mail</strong>, além de ser usado para operacionalizar seu login, é também eventualmente utilizado por nós, para nos comunicarmos com você, em caso de necessidade.</li>
          
        </ul>

        <p>Você pode ler mais sobre como tratamos seus dados em nossa <router-link data-dismiss="modal" to="/politica-de-privacidade">Política de Privacidade</router-link>.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>


      <!-- -->
  </div>
</template>

<style>
@media all and (max-width:768px){
.box-comprar{padding:5px !important;margin-bottom:5px;}
.bg-cadastro{background:url('/img/bg-cadastro.jpeg') !important;background-size: cover !important;background-repeat: no-repeat !important;background-position: 70%  !important;}
.box-comprar h5{font-size:.9em;}
.txt-preco{font-size:.9em !important;}
.promocao{width:60px;height:auto;top:0 !important;right:0 !important;}
}

@media all and (min-width:768px){
.bg-cadastro{background:url('/img/bg-cadastro.jpeg') !important;background-size: cover !important;background-repeat: no-repeat !important;}
}

.box-comprar{padding:20px;}
.bt-acao{border:2px solid #000;border-radius:20px;padding:6px;cursor:pointer;}
.input-cadastro::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFF !important;
  opacity: 1; /* Firefox */
}

.input-cadastro:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #FFF !important;
}

.input-cadastro::-ms-input-placeholder { /* Microsoft Edge */
  color: #FFF !important;
}

.input-cadastro{color: #FFF !important;border-color: #FFF !important;background:none !important;}
.espacamento{padding:0 40px;}
.fundo-laranja{background:#F18800;padding-bottom:20px;}
.fundo-roxo{background:#400041;padding-bottom:20px;}
.fundo-roxo2{background:#764573;padding-bottom:20px;}
.quadro{min-height:300px;}
.txt-white{color:#FFF;}
.txt-cadastro{color:#FFF;font-size:1.15em;}
.bt-enviar-cadastro {
   background-color: #FFF;
    color: #000;
    min-width:100% !important;
    display:block !important;
    padding:10px 0;
    border:none !important;
}
.bt-enviar-cadastro:hover{background-color: #400041;color:#FFF;}
</style>

<script>
import servidor from '@/servidor'
import Degustacao from '@/components/Degustacao'

export default {
  name: 'CadastroNovo',
  metaInfo: {
    title: 'Cadastro - Tarimba na cozinha',
    meta:[{name: 'description', 
    content: "Tarimba Na Cozinha - cadastro. Olá! Vamos iniciar o seu cadastro. Aqui, pedimos algumas informações básicas para que possamos conhecê-lo(a) e, assim, fazer do Tarimba na Cozinha a melhor solução para seu dia a dia."}]
  },
  components: {Degustacao},
  data(){
    return {
      promocao:'',
      showLoading:false,
      semResultados:false,
      nome:'',
      data:'',
      cpf:'',
      genero:'',
      cep:'',
      cidade:'',
      estado:'',
      nivel:'',
      historia:'',
      email:'',
      emailConfirma:'',
      senha:'',
      senhaConfirma:'',
      gratuito:null,
      termos:null,
      politica:null,
      texto:'',
      textoBotao:'COMPRAR',
      erro:false
    }
  },
  methods:{
     abrirModalCadastro(){
    window.jQuery('#modal-cadastro').modal('show');
   },
    abrirModalDegustacao(){
    window.jQuery('#modal-degustacao').modal('show');
   },
    abrirModalInfo(){
    window.jQuery('#modal-info').modal('show');
   },
    buscarCep(){
      if(this.cep.length == 9){
         fetch('https://viacep.com.br/ws/'+this.cep+'/json/')
          .then((response) => response.json())
          .then((data) => {

            if(data.erro != null){
                this.abrirModal("CEP informado é inválido!");
                this.cep = "";
            }else{
               this.cidade = data.localidade;
               this.estado = data.uf;
            }
          });
      }
      
    },
   validarCampos(){
    
     if(this.nome.length < 4){this.abrirModal("Nome deve conter pelo menos 4 caracteres!");return;}
      if(this.data.length > 0 && this.data.length < 10){this.abrirModal("Data de nascimento incorreta!");return;}
      //if(this.genero.length < 1){this.abrirModal("Campo gênero é obrigatório!");return;}
      //if(this.cidade.length < 3){this.abrirModal("Cidade é obrigatória e deve conter pelo menos 3 caracteres!");return;}
      //if(this.estado.length < 1){this.abrirModal("Estado é obrigatório!");return;}
      if(this.cep.length > 0 && this.cep.length < 9){this.abrirModal("Cep deve conter 9 caracteres!");return;}
      //if(this.cpf.length < 11){this.abrirModal("CPF é obrigatório e deve conter 11 caracteres!");return;}
      //if(this.isValidCPF(this.cpf) == false){this.abrirModal("CPF Inválido!");return;}
      //if(this.nivel.length < 1){this.abrirModal("Nível de pratica é obrigatório!");return;}
      if(this.email.length < 5){this.abrirModal("E-mail inválido!");return;}
      if(this.email != this.emailConfirma){this.abrirModal("Confirmação de E-mail não combina!");return;}
      if(this.senha.length < 8){this.abrirModal("Senha deve conter pelo menos 8 caracteres!");return;}
       if(this.senha != this.senhaConfirma){this.abrirModal("Confirmação de Senha não combina!");return;}
      //if(this.gratuito == null){this.abrirModal("Você não confirmou a respeito do (Tempo de gratuidade do sistema e sobre a cobrança da taxa não ser automática).!");return;}
      if(this.termos == null){this.abrirModal("Você não confirmou a respeito dos (Termos de uso).!");return;}
      if(this.politica == null){this.abrirModal("Você não confirmou a respeito da (Política de privacidade).!");return;}
      this.enviar();
   },

   abrirModal(msg){
    this.texto = msg;
    window.jQuery('#modal-validacao').modal('show');
   },
   isValidCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999" 
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++) 
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(9, 10)) ) return false
    soma = 0
    for (var j = 1; j <= 10; j++) 
        soma = soma + parseInt(cpf.substring(j-1, j)) * (12 - j)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false
    return true
    },
    enviar(){
      this.showLoading = true;
      let dados = new FormData();
      dados.append('nome',this.nome);
      dados.append('email',this.email);
      dados.append('senha',this.senha);
      dados.append('genero',this.genero);
      dados.append('cep',this.cep);
      dados.append('cpf',this.cpf);
      dados.append('nivel',this.nivel);
      dados.append('data',this.data);
      dados.append('cidade',this.cidade);
      dados.append('estado',this.estado);
      dados.append('historia',this.historia);
      dados.append('promocao',this.promocao);

      fetch(servidor+'verificacao/usuario',{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            console.log(data);

            if(data.erro != null){
                this.abrirModal(data.erro);
            }else{
               console.log('Registrado com sucesso');
               this.$store.commit('logarUsuario',data);
               this.setCookie('usuario',JSON.stringify(this.logado), this.logado.dias);
                //this.$store.commit('showAlertaCadastroFinalizado',true);
                window.jQuery('#modal-cadastro').modal('hide');
               this.abrirModal("Obrigado por se cadastrar em nossa plataforma! Agora você pode escolher um de nossos planos e efetuar o pagamento para desbloquear todas as nossas receitas.");
            }
          }).catch(this.showLoading = false);
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
   
   comprar(preco,tipo,id){

     if(this.$store.state.usuario != null){

      /*if(this.$store.state.usuario.cadastro != true){
        this.$store.commit('showAlertaCadastro',true);
        this.$router.push('/perfil');
        return;
      }*/

     this.showLoading = true;
     this.erro = false;
     this.textoBotao = "AGUARDE...";
     let dados = new FormData();
     dados.append('tipo',tipo);
     dados.append('id',id);
     dados.append('preco',preco);
     dados.append('usuario',this.$store.state.usuario.id);
     dados.append('nome',this.$store.state.usuario.nome);
     dados.append('email',this.$store.state.usuario.email);
   
      
      fetch(servidor+'pagseguro/checkout/',{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            this.textoBotao = "COMPRAR";
            console.log(data);
             window.location.href = data.url;
           //this.abrirPagamento(data.codigo[0]);

          }).catch(() => {
            this.textoBotao = "COMPRAR";
             this.showLoading = false;
              this.erro = true;
               
          })
      }else{
        this.abrirModalCadastro();
      }
   }
  },
  computed:{
    logado(){
      return this.$store.state.usuario;
    },
      ativo(){
      if(this.$store.state.usuario != null){
        let ativo =  this.$store.state.usuario.ativo;
        return ativo;
      }else{
        return true;
      }
      
      
    },
  },
  
  mounted(){
     
 this.$scrollTo("#topo");
  }
}
</script>